ion-content ion-toolbar {
  --background: translucent;
}

@media print {
  ion-toolbar {
    display: none;
  }

  ion-list {
    ion-item {
      ion-avatar {
        margin-top: 0;
        margin-bottom: 0;
      }

      ion-label {
        margin-top: 0;
        margin-bottom: 0;

        h3 {
          font-size: 12px !important;
        }
      }
    }
  }
}
