/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /* --ion-color-primary: #5c2019;
  --ion-color-primary-rgb: 92, 32, 25;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #511c16;
  --ion-color-primary-tint: #6c3630;

  --ion-color-secondary: #bc4639;
  --ion-color-secondary-rgb: 188, 70, 57;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #a53e32;
  --ion-color-secondary-tint: #c3594d;

  --ion-color-tertiary: #d4a59a;
  --ion-color-tertiary-rgb: 212, 165, 154;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #bb9188;
  --ion-color-tertiary-tint: #d8aea4;

  --ion-color-success: #3ae00b;
  --ion-color-success-rgb: 58, 224, 11;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #33c50a;
  --ion-color-success-tint: #4ee323;

  --ion-color-warning: #e0bd16;
  --ion-color-warning-rgb: 224, 189, 22;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #c5a613;
  --ion-color-warning-tint: #e3c42d;

  --ion-color-danger: #e02116;
  --ion-color-danger-rgb: 224, 33, 22;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #c51d13;
  --ion-color-danger-tint: #e3372d;

  --ion-color-dark: #334259;
  --ion-color-dark-rgb: 51, 66, 89;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #2d3a4e;
  --ion-color-dark-tint: #47556a;

  --ion-color-medium: #8b939f;
  --ion-color-medium-rgb: 139, 147, 159;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #7a818c;
  --ion-color-medium-tint: #979ea9;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9; */

  --ion-color-primary: #1d3557;
  --ion-color-primary-rgb: 29, 53, 87;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #1a2f4d;
  --ion-color-primary-tint: #344968;

  --ion-color-secondary: #457b9d;
  --ion-color-secondary-rgb: 69, 123, 157;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #3d6c8a;
  --ion-color-secondary-tint: #5888a7;

  --ion-color-tertiary: #a8dadc;
  --ion-color-tertiary-rgb: 168, 218, 220;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #94c0c2;
  --ion-color-tertiary-tint: #b1dee0;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffbd00;
  --ion-color-warning-rgb: 255, 189, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0a600;
  --ion-color-warning-tint: #ffc41a;

  --ion-color-danger: #e63946;
  --ion-color-danger-rgb: 230, 57, 70;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #ca323e;
  --ion-color-danger-tint: #e94d59;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-font-family: 'Rubik', sans-serif;
}

ion-title.title-large {
  --color: white;
}
