.ion-page {
  .layout-content {
    --background: none;
    background-size: cover;
  }

  &.groups .layout-content {
    background-image: url(/assets/neighborhood-splash.jpg);
  }

  &.sign-in .layout-content {
    background-image: url(/assets/logo.png);
  }

  &.profile .layout-content {
    background-image: url(/assets/friends-splash.jpg);
  }

  &.view-group .layout-content {
    background-image: url(/assets/splash1.jpg);
  }
  &.shopping-lists .layout-content {
    background-image: url(/assets/splash1.jpg);
  }
}

ion-toolbar {
  background-color: #fff;
}

.env-header {
  background-color: var(--background-color, #fff);
  &.danger {
    background-color: var(--ion-color-danger);

    ion-title {
      color: #fff;
    }
  }
}
