.attribution-line {
  font-size: 13px;

  .user-chip {
    font-size: 10px;
    height: 20px;
    padding-top: 6px;
  }
}

ion-badge.quantity-badge {
  font-size: 11px;
  margin-top: 0px;
}

ion-badge.dib-alert {
  font-size: 11px;

  ion-icon {
    margin-left: 3px;
    margin-right: 3px;
    position: relative;
    top: 1px;
  }
}
