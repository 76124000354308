.autocomplete-dropdown {
  position: absolute;
  width: 83%;
  height: 300px;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  margin-bottom: 20px;
  margin-top: -10px;
  left: 30px;
  z-index: 1000;
  background-color: #fff;
}

.autocomplete-dropdown ion-row {
  height: 280px;
  overflow-y: auto;
}
