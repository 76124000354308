p {
  padding-left: 0;
  padding-right: 0;
}
.app {
  background-image: url(/assets/splash2.jpg);
  background-size: cover;
}

.logo-bg {
  background-image: url(/assets/logo.png);
  background-size: cover;
}

.ion-icon-inline-text {
  position: relative;
  top: 2px;
  margin-right: 3px;
}

ion-fab-list {
  &.fab-list-with-labels {
    margin-bottom: 90px;
  }
}

ion-title {
  &.ion-title-stacked {
    position: relative;
  }

  &.ion-title-stacked-top {
    padding-bottom: 0;
  }
}

ion-note {
  ion-icon {
    position: relative;
    top: 2px;
  }
}

ion-title {
  padding-left: 30px;
  padding-right: 30px;
}

ion-card {
  ion-card-header {
    ion-button {
      z-index: 1000;
    }
  }
}

ion-menu.ion-menu-large {
  --width: 95%;
}

@media print {
  body.print {
    overflow: auto;
    position: relative;

    .ion-page {
      position: relative;
      overflow-y: auto;
      display: block;
    }
  }

  ion-tab-bar {
    display: none;
  }

  .ion-hide-print {
    display: none;
  }

  .ion-show-print {
    display: block !important;
  }
}

.ion-show-print {
  display: none;
}
